import "./header.css";

import { useHistory } from "react-router";

import { useContext, useState } from "react";

import { UserContext } from "../../core/providers/user";

import { NavLink } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/home/happybati.svg";

import Profile from "../../modals/profile/profile";

interface IMenuProps {
  setOpen: Function;
}

function Menu({ setOpen }: IMenuProps) {
  const { user } = useContext(UserContext);

  const history = useHistory();

  const handleClickEvent = function () {
    setOpen(false);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleRegisterEvent = function () {
    setOpen(false);

    history.push("/auth/register");

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleProfileEvent = function () {
    setOpen(false);

    history.push("/home/profile");

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="header-menu-wrapper">
      <NavLink
        to="/home/search"
        onClick={handleClickEvent}
        className="header-link"
        activeClassName="header-link-active"
      >
        <p>Rechercher</p>
      </NavLink>
      {user === null && (
        <NavLink
          to="/auth/login"
          onClick={handleClickEvent}
          className="header-link"
          activeClassName="header-link-active"
        >
          <p>Connexion</p>
        </NavLink>
      )}
      {user && (
        <NavLink
          to="/home/messages"
          onClick={handleClickEvent}
          className="header-link"
          activeClassName="header-link-active"
        >
          <p>Messages</p>
        </NavLink>
      )}
      {user && (
        <NavLink
          to="/home/ads"
          onClick={handleClickEvent}
          className="header-link"
          activeClassName="header-link-active"
        >
          <p>Mes annonces</p>
        </NavLink>
      )}
      {user === null && (
        <button
          className="register-menu-button"
          onClick={() => handleRegisterEvent()}
        >
          Inscription
        </button>
      )}
      {user && (
        <div className="header-profile" onClick={() => handleProfileEvent()}>
          <div>
            <p>{user.name}</p>
            <p>{user.email}</p>
          </div>
          <img src={user.avatar} alt="profile" />
        </div>
      )}
    </div>
  );
}

function Header() {
  const [open, setOpen] = useState<boolean>(false);
  const [profileOpen, setProfileOpen] = useState<boolean>(false);

  const history = useHistory();

  const { user } = useContext(UserContext);

  const handleRegisterEvent = function () {
    setOpen(false);

    history.push("/auth/register");
  };

  const handleProfileEvent = function () {
    setOpen(false);

    setProfileOpen(!profileOpen);
  };

  const handleMenuEvent = function () {
    setOpen(!open);
  };

  const handleHomeEvent = function () {
    history.push("/home/search");

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleClickEvent = function () {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <header>
      <Logo onClick={() => handleHomeEvent()} />
      <nav>
        <NavLink
          to="/home/search"
          className="header-link"
          activeClassName="header-link-active"
          onClick={handleClickEvent}
        >
          <p>Rechercher</p>
        </NavLink>
        {user === null && (
          <NavLink
            to="/auth/login"
            className="header-link"
            activeClassName="header-link-active"
            onClick={handleClickEvent}
          >
            <p>Connexion</p>
          </NavLink>
        )}
        {user && (
          <NavLink
            to="/home/messages"
            className="header-link"
            activeClassName="header-link-active"
            onClick={handleClickEvent}
          >
            <p>Messages</p>
          </NavLink>
        )}
        {user && (
          <NavLink
            to="/home/ads"
            className="header-link"
            activeClassName="header-link-active"
            onClick={handleClickEvent}
          >
            <p>Mes annonces</p>
          </NavLink>
        )}
        {user === null && (
          <button
            className="register-button"
            onClick={() => handleRegisterEvent()}
          >
            Inscription
          </button>
        )}
        {user && (
          <div className="header-profile" onClick={() => handleProfileEvent()}>
            <div>
              <p>{user.name}</p>
              <p>{user.email}</p>
            </div>
            <img src={user.avatar} alt="profile" />
          </div>
        )}
        {profileOpen && <Profile setProfileOpen={setProfileOpen} />}
      </nav>
      <button className="menu-button" onClick={() => handleMenuEvent()}>
        {open ? "Fermer" : "Menu"}
      </button>
      {open && <Menu setOpen={setOpen} />}
    </header>
  );
}

export default Header;
